<template>
  <div>
    <a-modal width="800px" :title="Title" :visible="visible" footer @cancel="cancel">
      <a-button type="primary" style="background:#60A2DE;" @click="Add">
        新增
      </a-button>
      <a-table :columns="columns" :data-source="sourceData">
        <template #operation="{ text, record }">
          <a-button type="link" @click="download(record.path)">下载附件</a-button>
          <a-popconfirm title="确认删除这条数据吗?" ok-text="确定" cancel-text="取消" @confirm="del(record)">
            <a-button type="link">删除</a-button>
          </a-popconfirm>
        </template>
      </a-table>
    </a-modal>
    <a-modal :visible="visibleAdd" width="800px" title="新增附件" @ok="handleOk" @cancel="Addcancel">
      <a-form ref="formRef" :model="formState" :rules="rules">
        <a-row>
          <a-col :span="12">
            <a-form-item label="培训附件" name="categoryId">
              <uploadAttachment ref="uploadAttachment" :FormData="FormData" @handleChange="handleChangeUpload">
              </uploadAttachment>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-modal>
  </div>
</template>
<script>
  import uploadAttachment from "@/views/components/uploadAttachment";
  import { defineComponent } from "vue";
  import { TrainingPlanToFileController_post, TrainingPlanToFileControllerPage, TrainingPlanToFileControllerr_del } from '@/api/api'
  const columns = [
    {
      title: "序号",
      width: 60,
      dataIndex: "index",
      customRender: (row) => row.index + 1,
    },
    {
      title: '附件名称',
      dataIndex: 'name',
      width: 200,
      ellipsis: true,
      key: 'name',
    },
    {
      title: '上传时间',
      dataIndex: 'createdTime',
      key: 'createdTime',
    },
    {
      title: "操作",
      dataIndex: "operation",
      width: 200,
      slots: { customRender: "operation" },
    },
  ];
  export default defineComponent({
    name: "index",
    props: {
      FormData: {
        type: String,
        required: false,
      },
    },
    data() {
      return {
        trainingPlanId: '',
        columns,
        visibleAdd: false,
        sourceData: [],
        visible: false,
        confirmLoading: false,
        formState: { trainingPlanId: '', path: '', name: '' },
        rules: {
        },
      };
    },
    components: {
      uploadAttachment,
    },
    methods: {
      //上传文件回调
      handleChangeUpload(info) {
        if (info.response.data?.path) {
          // this.formState.path = 'http://qyapi.ruanwe.com'+  info.response.data.path
          this.formState.path = info.response.data.path
          this.formState.name = info.response.data.name
        } else {
          this.$message.error('上传服务器失败，请重新上传')
        }
      },
      download(path) {
        window.open(path)
      },
      del(record) {
        TrainingPlanToFileControllerr_del({ id: record.trainingPlanFileId }).then(res => {
          this.Datainfo()
        })
      },
      Add() {
        this.visibleAdd = true
        this.$refs.uploadAttachment.init()
      },
      Addcancel() {
        this.$refs.formRef.resetFields()
        this.visibleAdd = false
      },
      cancel() {
        this.visible = false;
      },
      handleOk() {
        this.visibleAdd = false
        if (this.formState.path) {
          TrainingPlanToFileController_post(this.formState).then(res => {
            this.Addcancel()
            this.Datainfo()

          })
        } else {
          this.Addcancel()
          this.Datainfo()

        }
      },
      Datainfo() {
        TrainingPlanToFileControllerPage(
          {
            "currentPage": 0,
            "pageSize": 100,
            trainingPlanId: this.formState.trainingPlanId
          }
        ).then(res => {
          this.sourceData = res.data
        })
      },
      showModal(val, record) {
        this.Title = val
        this.formState.trainingPlanId = record.trainingPlanId
        this.Datainfo()
        this.visible = true;

      }
    },
  });
</script>
<style lang="less">
</style>