<template>
  <div>
    <a-modal width="1100px" :title="Title" :visible="visible" :confirm-loading="confirmLoading" @ok="handleOk"
      @cancel="cancel">
      <div style="height:600px;overflow:auto;padding:10px;">
        <a-form ref="formRef" :model="formState" :rules="rules">
          <div style="width:100%;border: 1px solid rgb(225, 225, 225);padding:10px;">
            <div style="height:40px;line-height: 40px;background:rgb(225, 225, 225);">
              <h2 style="margin-left:10px">培训基本信息</h2>
            </div>
            <a-row style="margin-top:5px">
              <a-col :span="12">
                <a-form-item label="培训标题：" name="title">
                  <a-input v-model:value="formState.title" style="width:80%" />
                </a-form-item>
              </a-col>
              <a-col :span="12">
                <a-form-item label="参训人员：" name="trainingPlanToUsers">
                  <a-button @click="trainees">选择</a-button>
                </a-form-item>
              </a-col>
            </a-row>
            <a-row style="margin-top:5px">
              <a-col :span="12">
                <a-form-item label="培训类型：" name="categoryId">
                  <a-select ref="select" style="width:80%" v-model:value="formState.categoryId" :options="options">
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :span="12">
                <a-form-item label="起止时间：" name="time">
                  <a-range-picker v-model:value="formState.time" :show-time="{
                    hideDisabledOptions: true,
                    defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('11:59:59', 'HH:mm:ss')],
                  }" value-format="YYYY-MM-DD HH:mm:ss" />
                </a-form-item>
              </a-col>

            </a-row>
            <a-row style="margin-top:5px">
              <a-col :span="12">
                <a-form-item label="封面图片：" name="img">
                  <uploadPictures ref="uploadPictures" :FormData="formState" @handleChange="handleChangeUploadPictures">
                  </uploadPictures>
                  <span style="margin-left:10px">封面格式为JPG、PNG格式，尺寸为4:3，大小不超过1M</span>
                </a-form-item>
              </a-col>
              <a-col :span="12">
                <a-form-item label="参训地点：" name="location">
                  <a-input v-model:value="formState.location" style="width:80%" />
                </a-form-item>
              </a-col>
            </a-row>
          </div>
        </a-form>
      </div>
    </a-modal>
    <popupTrainees ref="popupTrainees" @sourceData="popupTraineesSourceData" :FormData="formState.trainingPlanToUsers">
    </popupTrainees>
  </div>
</template>
<script>
import uploadPictures from "../../components/uploadPictures";
import popupTrainees from "../../components/popupTrainees"
import { defineComponent } from "vue";
import { find, TrainingPlanController_post, TrainingPlanController_id, TrainingPlanController_put } from '@/api/api'
import moment from 'moment';
export default defineComponent({
  name: "index",
  props: {
    Title: {
      type: String,
      required: false,
    },
    FormData: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      moment,
      editor: false,
      options: [],
      visible: false,
      confirmLoading: false,
      formState: {
        categoryId: '',
        title: '',
        time: [],
        trainingPlanToUsers: [],
        location: '',
        img: ''
      },
      rules: {
        title: [{ required: true, message: '不能为空', trigger: 'blur' }],
        categoryId: [{ required: true, message: '不能为空', trigger: 'blur' }],
        location: [{ required: true, message: '不能为空', trigger: 'blur' }],
        img: [{ required: true, message: '不能为空', trigger: 'blur' }],
        trainingPlanToUsers: [{
          required: true, message: '不能为空', trigger: 'change', transform: (value) => {
            if (value.length > 0) {
              return value.toString()
            }
            return value
          }
        }],
        time: [{
          required: true, message: '不能为空', trigger: 'change', transform: (value) => {
            if (value.length > 0) {
              return value.toString()
            }
            return value
          }
        }],
      },
    };
  },
  components: {
    uploadPictures,
    popupTrainees,
  },
  created() { },
  methods: {
    popupTraineesSourceData(val) {
      this.formState.trainingPlanToUsers = val
    },
    cancel() {
      this.$refs.formRef.resetFields()
      this.formState = {
        categoryId: '',
        title: '',
        time: [],
        trainingPlanToUsers: [],
        location: '',
        img: ''
      }
      this.visible = false;
    },
    //上传封面图片回调
    handleChangeUploadPictures(info) {
      if(info.response.data?.path){
        // this.formState.img = 'http://qyapi.ruanwe.com'+  info.response?.data.path
        this.formState.img = info.response?.data.path
      }else{
        this.$message.error('上传服务器失败，请重新上传')
      }
    },
    trainees() {
      this.$refs.popupTrainees.showModal('参训人员')
    },
    delClick(index) {
      this.sourceData.splice(index, 1)
    },
    handleOk() {
      this.$refs.formRef.validate().then(() => {
        this.confirmLoading = true;
        const params = {
          ...this.formState,
          startDate: this.formState.time[0],
          endDate: this.formState.time[1],
          type: 1
        }
        if (!this.editor) {
          TrainingPlanController_post(params).then(res => {
            this.cancel()
            this.confirmLoading = false;
            this.$parent.refresh()
          })
        } else {
          TrainingPlanController_put(params).then(res => {
            this.cancel()
            this.confirmLoading = false;
            this.$parent.refresh()
          })
        }
      });
    },
    showModal(val) {
      this.editor = false
      if (val) {
        this.editor = true
        TrainingPlanController_id(val).then(res => {
          this.formState = { ...this.formState, ...res.data }
          this.formState.time = []
          this.formState.time.push(this.formState.startDate)
          this.formState.time.push(this.formState.endDate)
          this.formState.trainingPlanToUsers = []
          res.data.trainingPlanToUsers.forEach(res => {
            this.formState.trainingPlanToUsers.push({ id: res.id, realName: res.name, department: res.department })
          })
          this.visible = true;
          this.$nextTick(() => {
            this.$refs.uploadPictures.init()
          })
        })
      } else {
        this.visible = true;
        this.$nextTick(() => {
          this.$refs.uploadPictures.init()
        })
      }
      this.visible = true;
      this.options = []
      find({ type: 3 }).then(res => {
        res.data.map(res => {
          this.options.push({
            value: res.categoryId,
            label: res.name,
          })
        })
      })

    }
  },
});
</script>
<style lang="less">
.table_h2 {
  width: 100%;
  border: 1px solid rgb(225, 225, 225);
}
</style>
    