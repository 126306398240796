<template>
  <div>
    <a-modal width="1100px" :title="Title" :visible="visible" :confirm-loading="confirmLoading" footer @cancel="cancel">
      <div style="height:600px;overflow:auto;padding:10px;">
        <a-form ref="formRef" :model="formState" :rules="rules">
          <a-row>
            <a-col :span="8">
              <a-form-item label="所属部门：" name="categoryId">
                <a-cascader v-model:value="formState.department" style="width:80%" allow-clear 
                  :field-names="{ label: 'departmentName', value: 'departmentId', children: 'children' }"
                  :options="optionsCategory" change-on-select placeholder="请选择" />
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item label="培训结果：" name="TrainingResults">
                <a-select ref="select" style="width:80%" v-model:value="formState.status" allow-clear  :options="options">
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item label="员工姓名：" name="name">
                <a-input v-model:value="formState.name" style="width:80%" allow-clear  />
              </a-form-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="8">
              <div v-if="source == 'offlineTraining'">
                <a-button type="danger"  @click="updateStatus(record, '1')"
                  :disabled="!trainingPlanUserIds.length > 0">
                  成绩合格
                </a-button>
                <a-button @click="updateStatus(record, '2')" :disabled="!trainingPlanUserIds.length > 0">
                  成绩不合格
                </a-button>
              </div>
            </a-col>
            <a-col :span="8">
            </a-col>
            <a-col :span="8">
              <div style="margin-left:200px">
                <a-button @click="reset">
                  重置
                </a-button>
                <a-button type="danger" @click="query">
                  查询
                </a-button>
              </div>
            </a-col>
          </a-row>
        </a-form>
        <a-spin :spinning="spinning">
          <a-table style="margin-top: 10px" :columns="columns" :data-source="sourceData" :pagination="false"
          :row-selection="{ selectedRowKeys: trainingPlanUserIds, onChange: onSelectChange }"
          row-key="trainingPlanUserId">
          <template #TrainingResults="{ text, record }">
            <span v-if="source == 'offlineTraining'" :style="text === 1  ? 'color:#21C28E' : 'color:red'">{{ text === 1 ?'成绩合格':(text === 2 ?'成绩不合格':'未完成')}}</span>
          </template>
        </a-table>
        </a-spin>
      </div>
    </a-modal>
  </div>
</template>
<script>
import { defineComponent } from "vue";
import { TrainingPlanToUserController_find, TrainingPlanToUserController_updateStatus } from '@/api/api'
import { departmentTree } from '@/api/system'

const columns = [
  {
    title: "序号",
    width: 60,
    dataIndex: "index",
    customRender: (row) => row.index + 1,
  },
  {
    title: '部门',
    dataIndex: 'department',
    key: 'department',
  },
  {
    title: "姓名",
    dataIndex: "name",
  },
  {
    title: "培训结果",
    dataIndex: "status",
    slots: { customRender: "TrainingResults" },
  },
];
export default defineComponent({
  name: "index",
  props: {
    FormData: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      spinning:true,
      trainingPlanUserIds: [],
      record: {},
      categoryIds: [],
      optionsCategory: [],
      options: ([
        {
          value: "2",
          label: "成绩不合格",
        },
        {
          value: "1",
          label: "成绩合格",
        },
        {
          value: "0",
          label: "未完成",
        },
      ]),
      formState: {
        department: [],
        status: undefined,
        name: undefined
      },
      //来源 线上 线下
      source: "",
      rules: {},
      sourceData: [
      ],
      columns,
      visible: false,
      confirmLoading: false,
      Title: '',
      node: ''
    };
  },
  created() { },
  methods: {
    onSelectChange(selectedRowKeys) {
      this.trainingPlanUserIds = selectedRowKeys;
    },
    updateStatus(val, status) {
      const params = {
        trainingPlanUserIds: this.trainingPlanUserIds,
        status: status
      }
      TrainingPlanToUserController_updateStatus(params).then(res => {
        this.api()
      })
    },
    cancel() {
      this.visible = false;
      this.reset()
    },
    reset(){
      this.formState= {
        department: [],
        status: undefined,
        name: undefined
      }
    },
    query() {
      this.api()
    },
    api() {
      this.spinning=true
      this.trainingPlanUserIds = []
      this.sourceData = []
      const params = {
        trainingPlanId: this.record.trainingPlanId,
        ...this.formState,
        department: this.formState.department[this.formState.department.length - 1]
      }
      TrainingPlanToUserController_find(params).then(res => {
        this.spinning=false
        this.sourceData = res.data
      })
    },
    showModal(val, record, source) {
      this.source = source
      this.Title = val
      this.visible = true;
      this.record = record
      this.api()
      departmentTree({}).then(res => {
        this.optionsCategory = res.data
      })
    }
  },
});
</script>
<style lang="less">

</style>
        